import React from "react";
import {Button, Divider, Icon, List, Modal, ModalHeader, Segment} from "semantic-ui-react";
import API from "../../utils/API";
import UserInfoEditForm from "./UserInfoEditForm";

export default class UserInfo extends React.Component {

    state = {
        userInfo: {
            first_name: '',
            last_name: '',
            email: '',
            username: ''
        },
        modalOpen: false,
        submitFunc: {}
    };

    componentDidMount() {
        this.getState();
    };

    getState = () => {
        API.getUserInfo(localStorage.getItem('auth_token'))
            .then(res => {
                this.setState({userInfo: res.data});
            });
    };

    updateState = data => {
        this.setState({userInfo: data.userInfo});
    };

    setModalOpen(isOpen) {
        this.setState({modalOpen: isOpen});
    };

    setSubmitFunc = func => {
        this.setState({submitFunc: func});
    };

    submitForm = () => {
        if (this.state.submitFunc) {
            this.state.submitFunc.current();
        }
    };

    render() {
        const { userInfo, modalOpen } = this.state;
        return (
            <Segment color='blue'>
                <h3>User Information</h3>
                <Divider />
                <List relaxed>
                    <List.Item>
                        <List.Header>First Name</List.Header>
                        {userInfo.first_name}
                    </List.Item>
                    <List.Item>
                        <List.Header>Last Name</List.Header>
                        {userInfo.last_name}
                    </List.Item>
                    <List.Item>
                        <List.Header>Email</List.Header>
                        {userInfo.email}
                    </List.Item>
                </List>
                <Modal
                    closeIcon
                    open={modalOpen}
                    trigger={<Button>Edit</Button>}
                    onClose={() => this.setModalOpen(false)}
                    onOpen={() => this.setModalOpen(true)}
                >
                    <ModalHeader content='Edit User Information' />
                    <Modal.Content scrolling>
                        <UserInfoEditForm data={userInfo} setSubmitFunc={this.setSubmitFunc} updateState={this.updateState} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setModalOpen(false)}>
                            <Icon name='remove' /> Cancel
                        </Button>
                        <Button onClick={() => {
                            this.setModalOpen(false)
                            this.submitForm();
                        }}>
                            <Icon name='checkmark' /> Save
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        );
    }
}
