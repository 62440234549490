import React, {useRef, useEffect, useState} from "react";
import {Form} from "semantic-ui-react";
import API from "../../utils/API";
import {toast} from "react-semantic-toasts";

export default function AccountInfoEditForm({data, setSubmitFunc, updateState}) {

    const [formData, setFormData] = useState(data);

    const submitRef = useRef(null);

    useEffect(() => {
        if (!!setSubmitFunc) {
            setSubmitFunc(submitRef);
        }
    }, []);

    const handleSubmit = () => {
        API.updateUserInfo(localStorage.getItem('auth_token'), formData)
            .then(res => {
                updateState({userInfo: res.data});
                toast({
                    title: 'Success',
                    description: 'The user information has been updated successfully',
                    type: 'success',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
            .catch(() => {
                toast({
                    title: 'Error',
                    description: 'The request could not be processed',
                    type: 'error',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
    }
    submitRef.current = handleSubmit;

    const onChange = (e) => {
        let formCopy = {...formData};
        formCopy[e.target.name] = e.target.value;
        if (e.target.name === 'email') {
            formCopy.username = e.target.value;
        }
        setFormData(formCopy);
    }

    return (
        <Form size='small' onSubmit={handleSubmit}>
            <Form.Input label='First Name' width={8} value={formData.first_name} name='first_name' onChange={onChange} />
            <Form.Input label='Last Name' width={8} value={formData.last_name} name='last_name' onChange={onChange} />
            <Form.Input label='Email' width={8} value={formData.email} name='email' onChange={onChange} />
        </Form>
    )
}
