import { Route, Redirect } from "react-router";
import PropTypes from "prop-types";
import React from "react";

/**
 * Private Route Component renders the route
 * only if the user is authorized to access the
 * application.
 * */
export default class PrivateRoute extends React.Component {

    static customPropCheck (props) {
        if (!(props.children || props.component)) {
            throw new Error("must provide at least one of 'children' or 'components' props");
        }
    }

    static propTypes = {
        component: PropTypes.any,
        path: PropTypes.string
    }

    constructor (props) {
        super(props);
        PrivateRoute.customPropCheck(props);
    }

    render () {
        const {children, component, path} = this.props;
        const auth = localStorage.getItem('auth_token');

        // Return the appropriate routing element.
        // Unauthorized access will be redirected to the login page.
        return auth && auth !== 'null'
            ? <Route component={component} path={path}>{children}</Route>
            : (<Redirect to={{ pathname: '/login' }} />);
    }
}
