import React from "react";
import {Button, Container, Divider, Message} from "semantic-ui-react";

export default class MFABackup extends React.Component {

    render() {
        return (
            <div>
                <p>
                    With Multi-Factor Authentication enabled,
                    you could be locked out of your account
                    if you lose access to your email or mobile device.
                    You can use each of these backup codes one time to login to this account.
                    We recommend storing these codes in a secure location, in case they are needed.
                </p>
                <Container textAlign='center'>
                    <Message compact size='huge'>
                        <Message.List items={this.props.backupCodes} />
                    </Message>
                </Container>
                <Divider />
                <Button floated='right' onClick={() => this.props.setModalOpen(false)} content='Done' style={{marginBottom: 15}} />
            </div>
        );
    }
}
