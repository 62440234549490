import React from "react";
import {Icon, Table} from "semantic-ui-react";
import FormatTime from "../../utils/FormatTime";
import UserEditModal from "./UserEditModal";

export default class UserTable extends React.Component {

    render() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.HeaderCell>Created</Table.HeaderCell>
                        <Table.HeaderCell>Last Login (UTC)</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.values(this.props.users).map(
                        ({ first_name, last_name, email, last_login, date_joined }) => {
                            return (
                                <Table.Row>
                                    <Table.Cell>{last_name}{last_name !== '' && first_name !== '' ? ', ' : ''}{first_name}<br/><i>{email}</i></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell>{FormatTime.displayDate(date_joined)}</Table.Cell>
                                    <Table.Cell>{FormatTime.displayDateTime(last_login)}</Table.Cell>
                                    <Table.Cell>
                                        <UserEditModal first_name={first_name}
                                                       last_name={last_name}
                                                       email={email}
                                                       currentUser={this.props.currentUser}
                                                       handleEdit={this.props.handleEdit}
                                        />
                                        {
                                            this.props.currentUser !== email &&
                                            <Icon link name='trash alternate' size='large' row={email} onClick={this.props.handleDelete}/>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            );
                        }
                    )}
                </Table.Body>
            </Table>
        );
    }
}
