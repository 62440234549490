import React, {Component} from "react";
import {Button, Container, Divider, Form, Image, Message, Segment} from "semantic-ui-react";
import GDCLogo from "../../styles/images/GDCLogo_Full_With_TM_04.jpg";
import API from "../../utils/API";
import {Link} from "react-router-dom";


export default class ResetPasswordRequest extends Component {

    state = {
        email: '',
        error: false,
        errorMsg: [],
        success: false
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { email } = this.state;

        API.postResetPasswordRequest(email)
            .then(response => {
                this.setState({error: false, success: true});
            })
            .catch(error => {
                this.setState({error: true});
                if (error.response.data) {
                    this.setState({errorMsg: Object.values(error.response.data)[0]});
                }
            });
    }

    render() {
        return (
            <div>
                <Container className='login-container' text>
                    <Segment padded>
                        <Image src={GDCLogo} alt="GDC Logo" size='medium' centered />
                        <Divider hidden />
                        <h3>Request New Password</h3>
                        <Form error={this.state.error} hidden={this.state.success} onSubmit={this.handleSubmit}>
                            <Form.Input
                                placeholder='Email'
                                name='email'
                                onChange={this.onChange}
                            />
                            <Message
                                error
                                header='Action Forbidden'
                                list={this.state.errorMsg}
                            />
                            <Button type='submit' content='Submit' />
                        </Form>
                        <div hidden={!this.state.success}>
                            <Message success
                                     header='Password Reset Request Sent'
                                     content='Please check your email for a link to reset your password.'
                            />
                            <Link to="/login">Return to login</Link>
                        </div>
                    </Segment>
                </Container>
            </div>
        );
    }
}
