import React, {Component} from "react";
import {Button, Container, Form, Message, Segment} from "semantic-ui-react";
import API from "../../utils/API";

export default class ChangePasswordForm extends Component {

    state = {
        current_password: '',
        new_password: '',
        re_new_password: '',
        error: false,
        errorMsg: [],
        success: false
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { current_password, new_password, re_new_password } = this.state;

        API.postSetPassword(localStorage.getItem('auth_token'),
            {current_password, new_password, re_new_password})
            .then(response => {
                this.setState({error: false, success: true});
            })
            .catch(error => {
                this.setState({error: true});
                if (error.response.data) {
                    this.setState({errorMsg: Object.values(error.response.data)[0]});
                }
            });
    }

    render() {
        return (
            <div>
                <Container className='login-container' text>
                    <Segment padded>
                        <h3>Change Password</h3>
                        <Form error={this.state.error} hidden={this.state.success} onSubmit={this.handleSubmit}>
                            <Form.Input
                                type='password'
                                placeholder='Current Password'
                                name='current_password'
                                onChange={this.onChange}
                            />
                            <Form.Input
                                type='password'
                                placeholder='New Password'
                                name='new_password'
                                onChange={this.onChange}
                            />
                            <Form.Input
                                type='password'
                                placeholder='Confirm New Password'
                                name='re_new_password'
                                onChange={this.onChange}
                            />
                            <Message
                                error
                                header='Action Forbidden'
                                list={this.state.errorMsg}
                            />
                            <Button type='submit' content='Submit' />
                        </Form>
                        <div hidden={!this.state.success}>
                            <Message success header='Password Updated Successfully' />
                            <a href='/login'>Go to login</a>
                        </div>
                    </Segment>
                </Container>
            </div>
        );
    }
}
