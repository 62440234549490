import React from "react";
import {Button, Divider, Modal, ModalHeader, Radio, Segment} from "semantic-ui-react";
import API from "../../utils/API";
import {history} from "../../Routes";
import MFASetup from "./mfa/MFASetup";
import MFAActive from "./mfa/MFAActive";

export default class Security extends React.Component {

    state = {
        mfaEnabled: false,
        mfaPrimaryMethod: '',
        mfaMethods: [],
        modalOpen: false,
    };

    componentDidMount() {
        this.getState();
    };

    getState = () => {
        API.getUserMfaMethod(localStorage.getItem('auth_token'))
            .then(res => {
                if (res.data.length > 0) {
                    this.setState({mfaEnabled: true});
                    res.data.forEach((method) => {
                        if (method.is_primary === true) {
                            this.setState({mfaPrimaryMethod: method.name})
                        }
                        this.setState(prevState => ({
                            mfaMethods: [...prevState.mfaMethods, method.name]
                        }));
                    });
                } else {
                    this.setState({mfaEnabled: false});
                }
            });
    };

    setModalOpen = (isOpen) => {
        this.setState({modalOpen: isOpen});
        if (isOpen === false) {
            this.getState();
        }
    };

    handleToggle = () => {
        if (this.state.mfaEnabled === false) {
            this.setModalOpen(true);
            this.setState({mfaEnabled: true});
        } else {
            if (window.confirm('Are you sure you want to deactivate Multi-Factor Authentication?')) {
                this.setState({mfaEnabled: false});
                API.postMfaDeactivation(localStorage.getItem('auth_token'), this.state.mfaPrimaryMethod)
                    .then(res => {
                        this.getState();
                    });
            }
        }
    }

    render() {
        const { modalOpen } = this.state;
        return (
            <Segment color='blue'>
                <h3>Security</h3>
                <Divider />
                <h4>Multi-Factor Authentication</h4>
                <Segment secondary compact>
                    Multi-Factor Authentication provides added security to your account.
                    When enabled, a security code will be required for login in addition to your username and password.
                </Segment>
                <Radio toggle
                       label='Enable MFA (Recommended)'
                       checked={this.state.mfaEnabled}
                       onChange={() => this.handleToggle()}
                       style={{marginBottom: 15}}
                />

                {this.state.mfaEnabled && <MFAActive method={this.state.mfaPrimaryMethod} />}
                <Divider />
                <h4>Password</h4>
                <Button onClick={() => {history.push('/change_password')}}>Change Password</Button>
                <Modal
                    closeIcon
                    open={modalOpen}
                    onClose={() => this.setModalOpen(false)}
                    onOpen={() => this.setModalOpen(true)}
                >
                    <ModalHeader content='Multi-Factor Authentication Setup' />
                    <Modal.Content>
                        <MFASetup setModalOpen={this.setModalOpen}/>
                    </Modal.Content>
                </Modal>
            </Segment>
        );
    }
}
