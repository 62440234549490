import React from "react";
import {ButtonGroup, Button, Divider} from "semantic-ui-react";
import {history} from "../../Routes";


export default class Sidebar extends React.Component {

    handleClick = (page) => {
        history.push("/" + page);
    }

    render() {
        return (
            <div className="sidebar">
                <div className="sidebar-container">
                    <ButtonGroup vertical>
                        <Button
                            className="sidebar-buttons"
                            icon="building outline"
                            content=" Account Information"
                            onClick={() => this.handleClick('account')}
                        />
                        <Button
                            className="sidebar-buttons"
                            icon="key"
                            content=" API Keys"
                            onClick={() => this.handleClick('apikeys')}
                        />
                        <Button
                            className="sidebar-buttons"
                            icon="settings"
                            content=" Tenants"
                            onClick={() => this.handleClick('tenants')}
                        />
                        <Button
                            className="sidebar-buttons"
                            icon="users"
                            content=" Users"
                            onClick={() => this.handleClick('users')}
                        />
                        {/*<Button*/}
                        {/*    className="sidebar-buttons"*/}
                        {/*    icon="money bill alternate outline"*/}
                        {/*    content=" Billing"*/}
                        {/*/>*/}
                        <Divider />
                        <Button
                            className="sidebar-buttons"
                            icon="question"
                            content=" Support"
                            onClick={() => this.handleClick('support')}
                        />
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}
