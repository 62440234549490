import React from "react";
import {Button, Form, Icon, Modal, ModalHeader} from "semantic-ui-react";
import {history} from "../../Routes";
import API from "../../utils/API";
import {toast} from "react-semantic-toasts";

export default class UserEditModal extends React.Component {

    state = {
        formData: {
            email: '',
            first_name: '',
            last_name: ''
        },
        modalOpen: false
    }

    updateState = (email, first_name, last_name) => {
        this.setState({
            formData: {
                email: email,
                first_name: first_name,
                last_name: last_name
            }
        })
    }

    setModalOpen(isOpen) {
        this.setState({modalOpen: isOpen});
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value.trim();
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    handleEditClick = (email, first_name, last_name) => {
        if (this.props.currentUser === email) {
            history.push('/profile');
        } else {
            this.updateState(email, first_name, last_name)
        }
    }

    sendResetEmail = (email) => {
        API.postResetPasswordRequest(email)
            .then(() => {
                this.setModalOpen(false);
                toast({
                    title: 'Success',
                    description: 'A password reset email has been sent to the selected user successfully',
                    type: 'success',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
            .catch(() => {
                this.setModalOpen(false);
                toast({
                    title: 'Error',
                    description: 'The request could not be processed',
                    type: 'error',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            });
    }

    render() {
        const {modalOpen, formData} = this.state;
        const {first_name, last_name, email} = this.props;
        return (
            <Modal
                closeIcon
                open={modalOpen}
                trigger={
                    <Icon link
                          name='pencil alternate'
                          size='large' style={{'marginRight': '15px'}}
                          onClick={() => this.handleEditClick(email, first_name, last_name)}
                    />
                }
                onClose={() => this.setModalOpen(false)}
                onOpen={() => this.setModalOpen(true)}
            >
                <ModalHeader content='Edit User' />
                <Modal.Content>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Input label='First Name' name='first_name' value={formData.first_name} onChange={this.handleChange} />
                            <Form.Input label='Last Name' name='last_name' value={formData.last_name} onChange={this.handleChange} />
                        </Form.Group>
                    </Form>
                    <Button icon='redo' labelPosition='left' content='Reset Password' onClick={() => {this.sendResetEmail(email)}} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setModalOpen(false)}>Cancel</Button>
                    <Button onClick={() => {
                        this.props.handleEdit(formData);
                        this.setModalOpen(false);
                    }}>Save</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
