import React from "react";
import {Breadcrumb, Button, Container, Divider, List, Modal, ModalHeader, Icon, Segment} from 'semantic-ui-react';
import Header from "../home-page/Header";
import Sidebar from "../home-page/Sidebar";
import AccountInfoEditForm from "./AccountInfoEditForm";
import API from "../../utils/API";
import { SemanticToastContainer } from 'react-semantic-toasts';


export default class AccountInfo extends React.Component {

    state = {
        orgInfo: {
            org: {
                display_name: '',
                email: '',
                phone: '',
            },
            address: {
                thoroughfare: '',
                premise: '',
                locality: '',
                region: '',
                postal_code: '',
                country: '',
            }
        },
        modalOpen: false,
        submitFunc: {}
    }

    setSubmitFunc = func => {
        this.setState({submitFunc: func});
    }

    submitForm = () => {
        if (this.state.submitFunc) {
            this.state.submitFunc.current();
        }
    };

    setModalOpen(isOpen) {
        this.setState({modalOpen: isOpen});
    }

    componentDidMount() {
        this.getState();
    }

    getState = () => {
        API.getOrgInfo(localStorage.getItem('auth_token'))
            .then(res => {
                this.setState({orgInfo: res.data});
            });
    }

    updateState = data => {
        this.setState({orgInfo: data.orgInfo});
    }

    render() {
        const {orgInfo, modalOpen} = this.state;
        return (
            <div>
                <Header />
                <Sidebar />
                <div className="page-content-container">
                    <Breadcrumb>
                        <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section link>Admin</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>Account Information</Breadcrumb.Section>
                    </Breadcrumb>
                    <Divider hidden />
                    <Container>
                        <SemanticToastContainer />
                        <Segment color='blue'>
                            <h3>Account Information</h3>
                            <Divider />
                            <List relaxed>
                                <List.Item>
                                    <List.Header>Account Name</List.Header>
                                    {orgInfo.org.display_name}
                                </List.Item>
                                <List.Item>
                                    <List.Header>Email</List.Header>
                                    {orgInfo.org.email}
                                </List.Item>
                                <List.Item>
                                    <List.Header>Phone</List.Header>
                                    {orgInfo.org.phone}
                                </List.Item>
                                <List.Item>
                                    <List.Header>Address</List.Header>
                                    {orgInfo.address.thoroughfare ? (<div>{orgInfo.address.thoroughfare} </div>) : null}
                                    {orgInfo.address.premise ? (<div>{orgInfo.address.premise} </div>) : null}
                                    {orgInfo.address.locality ? (<span>{orgInfo.address.locality}, </span>) : null}
                                    {orgInfo.address.region ? (<span>{orgInfo.address.region} <br/></span>) : null}
                                    {orgInfo.address.postal_code ? (<span>{orgInfo.address.postal_code}, </span>) : null}
                                    {orgInfo.address.country ? (<span>{orgInfo.address.country} <br/></span>) : null}
                                </List.Item>
                            </List>
                            <Modal
                                closeIcon
                                open={modalOpen}
                                trigger={<Button>Edit</Button>}
                                onClose={() => this.setModalOpen(false)}
                                onOpen={() => this.setModalOpen(true)}
                            >
                                <ModalHeader content='Edit Account Information' />
                                <Modal.Content scrolling>
                                    <AccountInfoEditForm data={orgInfo} setSubmitFunc={this.setSubmitFunc} updateState={this.updateState} />
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => this.setModalOpen(false)}>
                                        <Icon name='remove' /> Cancel
                                    </Button>
                                    <Button onClick={() => {
                                        this.setModalOpen(false)
                                        this.submitForm();
                                    }}>
                                        <Icon name='checkmark' /> Save
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </Segment>
                    </Container>
                </div>
            </div>
        );
    }

}
