import React from "react";
import Header from "../home-page/Header";
import Sidebar from "../home-page/Sidebar";
import { Breadcrumb, Container, Divider } from "semantic-ui-react";
import {Link} from "react-router-dom";
import {SemanticToastContainer} from "react-semantic-toasts";
import UserInfo from "./UserInfo";
import Security from "./Security";

export default class Profile extends React.Component {

    render() {
        return (
            <div>
                <Header />
                <Sidebar />
                <div className="page-content-container">
                    <Breadcrumb>
                        <Breadcrumb.Section><Link to='/'>Home</Link></Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>Profile</Breadcrumb.Section>
                    </Breadcrumb>
                    <Divider hidden />
                    <Container>
                        <SemanticToastContainer />
                        <UserInfo />
                        <Security />
                    </Container>
                </div>
            </div>
        );
    }
}
