import React from 'react';
import Header from "../home-page/Header";
import Sidebar from "../home-page/Sidebar";
import ApiKeysTable from "./ApiKeysTable";
import {Breadcrumb, Button, Container, Form, Modal, ModalHeader} from "semantic-ui-react";
import API from "../../utils/API";
import ApiKeySecret from "./ApiKeySecret";
import {SemanticToastContainer, toast} from "react-semantic-toasts";

export default class ApiKeysPage extends React.Component {

    state = {
        keys: [],
        tenants: [],
        modalOpen: false,
        selectedTenant: '',
        apiKey: '',
        apiSecret: ''
    }

    componentDidMount() {
        this.getState();
    }

    getState = () => {
        API.getApiKeys(localStorage.getItem('auth_token'))
            .then(res => {
                this.setState({keys: res.data});
            });
    }

    getTenantOptions = () => {
        return (
            this.state.tenants.map(t => ({
               key: t.name,
               text: t.name,
               value: t.name,
            }))
        );
    }

    onModalOpen() {
        this.setModalOpen(true);
        if(this.state.tenants !== []) {
            API.getTenants(localStorage.getItem('auth_token'))
                .then(res => {
                    this.setState({tenants: res.data.tenants});
                });
        }
    }

    setModalOpen(isOpen) {
        this.setState({
            modalOpen: isOpen,
            selectedTenant: '',
            apiKey: '',
            apiSecret: ''
        });
        if (isOpen === false) {
            this.getState();
        }
    }

    generateApiKey = () => {
        API.postApiKeys(localStorage.getItem('auth_token'),
            {
                'key': '',
                'task': 'add',
                'tenant': this.state.selectedTenant
            })
            .then(res => {
                this.setState({apiKey: res.data.apiKey.apiKey, apiSecret: res.data.apiKey.apiSecret});
                toast({
                    title: 'Success',
                    description: 'The new API key has been added successfully',
                    type: 'success',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
            .catch(() => {
                this.setModalOpen(false);
                toast({
                    title: 'Error',
                    description: 'The request could not be processed',
                    type: 'error',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })

    }

    onTenantChange = (e, data) => {
        this.setState({selectedTenant: data.value});
    }

    handleDeleteClick = (e, data) => {
        if (window.confirm('Are you sure you want to delete API key ' + data.row + '?')) {
            API.postApiKeys(localStorage.getItem('auth_token'),
                {
                    'key': data.row,
                    'task': 'delete'
                })
                .then(() => {
                    this.getState();
                    toast({
                        title: 'Success',
                        description: 'The API key has been deleted successfully',
                        type: 'success',
                        size: 'small',
                        time: 5000,
                        animation: 'slide down'
                    });
                })
                .catch(() => {
                    toast({
                        title: 'Error',
                        description: 'The request could not be processed',
                        type: 'error',
                        size: 'small',
                        time: 5000,
                        animation: 'slide down'
                    });
                })
        }
    }

    render() {
        const {modalOpen} = this.state;
        return (
            <div>
                <Header />
                <Sidebar />
                <div className="page-content-container">
                    <Breadcrumb>
                        <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section link>Admin</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>API Keys</Breadcrumb.Section>
                    </Breadcrumb>
                    <Container>
                        <SemanticToastContainer />
                        <Modal
                            closeIcon
                            open={modalOpen}
                            trigger={<Button content='Add API Key' icon='plus' labelPosition='left' floated='right' style={{"margin-bottom": "10px"}}/>}
                            onClose={() => this.setModalOpen(false)}
                            onOpen={() => this.onModalOpen()}
                        >
                            <ModalHeader content='Add API Key' />
                            <Modal.Content>
                                <Form>
                                    <Form.Group>
                                        <Form.Select label='Select Tenant' placeholder='Tenant' search options={this.getTenantOptions()} onChange={this.onTenantChange} />
                                        <Form.Button onClick={this.generateApiKey} style={{marginTop: '22px'}}>Create API Key</Form.Button>
                                    </Form.Group>
                                </Form>
                                {this.state.apiKey !== '' && <ApiKeySecret apiKey={this.state.apiKey} apiSecret={this.state.apiSecret} />}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setModalOpen(false)}>Done</Button>
                            </Modal.Actions>
                        </Modal>
                        <ApiKeysTable keys={this.state.keys} handleDeleteClick={this.handleDeleteClick}/>
                    </Container>
                </div>
            </div>
        );
    }
}
