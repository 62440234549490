import React from 'react';
import Header from "../home-page/Header";
import Sidebar from "../home-page/Sidebar";
import {Breadcrumb, Button, Container, Form, Modal, ModalHeader} from "semantic-ui-react";
import API from "../../utils/API";
import UserTable from "./UserTable";
import {Link} from "react-router-dom";
import {SemanticToastContainer, toast} from "react-semantic-toasts";

export default class UsersPage extends React.Component {

    state = {
        users: [],
        formData: {
            email: '',
            first_name: '',
            last_name: ''
        },
        currentUser: '',
        modalOpen: false
    }

    componentDidMount() {
        this.getState();
        this.getCurrentUser();
    }

    getState = () => {
        API.getUsers(localStorage.getItem('auth_token'))
            .then(res => {
                this.setState({users: res.data});
            });
    }

    getCurrentUser = () => {
        API.getUserInfo(localStorage.getItem('auth_token'))
            .then(res => {
                this.setState({currentUser: res.data.email});
            })
    }

    setModalOpen(isOpen) {
        this.setState({modalOpen: isOpen});
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value.trim();
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    handleDelete = (e, data) => {
        if (window.confirm('Are you sure you want to delete user ' + data.row + '?')) {
            API.postUserDelete(localStorage.getItem('auth_token'),
                {
                    'email': data.row
                })
                .then(() => {
                    this.getState();
                    toast({
                        title: 'Success',
                        description: 'The user has been deleted successfully',
                        type: 'success',
                        size: 'small',
                        time: 5000,
                        animation: 'slide down'
                    });
                })
                .catch(() => {
                    toast({
                        title: 'Error',
                        description: 'The request could not be processed',
                        type: 'error',
                        size: 'small',
                        time: 5000,
                        animation: 'slide down'
                    });
                })
        }
    }

    handleEdit = (data) => {
        API.postUserEdit(localStorage.getItem('auth_token'), data)
            .then(() => {
                this.getState();
                toast({
                    title: 'Success',
                    description: 'The information has been updated successfully',
                    type: 'success',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
            .catch(() => {
                toast({
                    title: 'Error',
                    description: 'The request could not be processed',
                    type: 'error',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
    }

    handleSubmit() {
        API.postUserAdd(localStorage.getItem('auth_token'), this.state.formData)
            .then(response => {
                this.getState();
                API.postResetPasswordRequest(response.data.email).then(() => {});
                toast({
                    title: 'Success',
                    description: 'The new user has been added successfully',
                    type: 'success',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
            .catch(() => {
                toast({
                    title: 'Error',
                    description: 'The request could not be processed',
                    type: 'error',
                    size: 'small',
                    time: 5000,
                    animation: 'slide down'
                });
            })
        this.setModalOpen(false);
    }

    render() {
        const {modalOpen, formData} = this.state;
        return (
            <div>
                <Header />
                <Sidebar />
                <div className="page-content-container">
                    <Breadcrumb>
                        <Breadcrumb.Section><Link to='/'>Home</Link></Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section link>Admin</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>Users</Breadcrumb.Section>
                    </Breadcrumb>
                    <Container>
                        <SemanticToastContainer />
                        <Modal
                            closeIcon
                            open={modalOpen}
                            trigger={<Button content='Add User' icon='add user' labelPosition='left' floated='right' style={{"marginBottom": "10px"}}/>}
                            onClose={() => this.setModalOpen(false)}
                            onOpen={() => this.setModalOpen(true)}
                        >
                            <ModalHeader content='Add User' />
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input label='Email' name='email' value={formData.email} onChange={this.handleChange} />
                                        <Form.Input label='First Name' name='first_name' value={formData.first_name} onChange={this.handleChange} />
                                        <Form.Input label='Last Name' name='last_name' value={formData.last_name} onChange={this.handleChange} />
                                    </Form.Group>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setModalOpen(false)}>Cancel</Button>
                                <Button onClick={() => this.handleSubmit()}>Save</Button>
                            </Modal.Actions>
                        </Modal>
                        <UserTable currentUser={this.state.currentUser} users={this.state.users} handleDelete={this.handleDelete} handleEdit={this.handleEdit} />
                    </Container>
                </div>
            </div>
        );
    }
}
