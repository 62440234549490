import React from "react";
import {Button, Container, Divider, Form} from "semantic-ui-react";
import API from "../../../utils/API";

export default class MFARegister extends React.Component {

    state = {
        selectedMethod: '',
        allowNext: false
    }

    updateMethod = (e, data) => {
        this.setState({selectedMethod: data.value, allowNext: true});
    }

    submitMfaMethod = () => {
        API.postMfaActivation(localStorage.getItem('auth_token'), this.state.selectedMethod)
            .then(res => {
                if (this.state.selectedMethod === 'app') {
                    this.props.updateParent({
                        qrCode: res.data.qr_link
                    });
                }
            })
        this.props.updateParent({
            selectedMethod: this.state.selectedMethod,
            activeStep: 'verify'
        });
    }

    render() {
        return (
            <Container>
                <p>Choose how you would like to receive your security code.</p>
                <Form>
                    <Form.Select
                        width={8}
                        label='MFA Method'
                        placeholder='Please select an option'
                        onChange={this.updateMethod} options={[{key: 'email', value: 'email', text: 'Email'},{key: 'app', value: 'app', text: 'Google Authenticator'}]}
                    />
                </Form>
                <Divider />
                <Button floated='right' disabled={!this.state.allowNext} onClick={this.submitMfaMethod} content='Next' style={{marginBottom: 15}} />
            </Container>
        );
    }
}
