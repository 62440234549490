import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import HomePage from "./components/home-page/HomePage";
import LoginPage from "./components/login/LoginPage";
import AccountInfo from "./components/account-info-page/AccountInfo";
import TenantPage from "./components/tenant-page/TenantPage";
import ApiKeysPage from "./components/api-keys-page/ApiKeysPage";
import UsersPage from "./components/users-page/UsersPage";
import Profile from "./components/profile-page/Profile";
import PrivateRoute from "./utils/PrivateRoute";
import ResetPasswordRequest from "./components/login/ResetPasswordRequest";
import ResetPasswordForm from "./components/login/ResetPasswordForm";
import ChangePasswordForm from "./components/profile-page/ChangePasswordForm";
import SupportPage from "./components/support-page/SupportPage";
import MFALoginCode from "./components/login/MFALoginCode";

export const history = createBrowserHistory();


export default function Routes(props) {

    return(
        <Router history={history}>
            <Switch>
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/login/code" component={MFALoginCode}/>
                <Route exact path="/reset_password_request" component={ResetPasswordRequest}/>
                <Route exact path="/reset_password_confirm/:uid/:token" component={ResetPasswordForm}/>

                <PrivateRoute
                    exact path="/" component={HomePage}
                />
                <PrivateRoute
                    exact path="/account" component={AccountInfo}
                />
                <PrivateRoute
                    exact path="/apikeys" component={ApiKeysPage}
                />
                <PrivateRoute
                    exact path="/change_password" component={ChangePasswordForm}
                />
                <PrivateRoute
                    exact path="/profile" component={Profile}
                />
                <PrivateRoute
                    exact path="/tenants" component={TenantPage}
                />
                <PrivateRoute
                    exact path="/support" component={SupportPage}
                />
                <PrivateRoute
                    exact path="/users" component={UsersPage}
                />
            </Switch>
        </Router>
    );
}
