import React from "react";
import {Table, Icon} from "semantic-ui-react"
import FormatTime from "../../utils/FormatTime";

export default class APIKeysTableRow extends React.Component {
    render() {
        if (this.props.tenant) {
            const t = this.props.tenant;
            return (
                <>
                    <Table.Cell>{t.apiKey}</Table.Cell>
                    <Table.Cell>{FormatTime.displayDateTime(t.created_date)}</Table.Cell>
                    <Table.Cell><Icon link name='trash alternate' size='large' row={t.apiKey}
                                      onClick={this.props.handleDeleteClick}/></Table.Cell>
                </>
            );
        } else {
            return null;
        }
    }
}
