import React, {Component} from "react";
import {Container, Divider, Image, Segment} from 'semantic-ui-react';
import GDCLogo from "../../styles/images/GDCLogo_Full_With_TM_04.jpg";
import LoginForm from "./LoginForm";

export default class LoginPage extends Component {
    render() {
        return (
            <Container className='login-container' text>
                <Segment padded>
                    <Image src={GDCLogo} alt="GDC Logo" size='medium' centered />
                    <Divider hidden />
                    <LoginForm />
                </Segment>
            </Container>
        );
    }
}
