export default {

    displayDate: (dateString) => {
        if (dateString) {
            return (new Date(dateString)).toISOString().split("T")[0].replace(/-/g, "/");
        }
        return '';
    },

    displayDateTime: (dateString) => {
        if (dateString) {
            return (new Date(dateString)).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
        }
        return '';
    },
}
