import React, { Component }from "react";
import {Button, Icon, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import GDCLogo from "../../styles/images/GDCLogo_Full_With_TM_04.jpg";
import {history} from "../../Routes";
import API from "../../utils/API";

export default class Header extends Component {

    handleLogoutClick = () => {
        API.userLogout(localStorage.getItem('auth_token'))
            .then(function() {
                localStorage.setItem('auth_token', null);
                history.push("/login");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {

        return (
            <Menu fixed="top">
                <Menu.Item header>
                    <Link to="/">
                        <img alt="GDC Logo" src={GDCLogo} className="gdc-logo"/>
                    </Link>
                </Menu.Item>
                <Menu.Item header>
                    <Link to="#">Admin</Link>
                </Menu.Item>
                {/*<Menu.Item header>*/}
                {/*    <Link to="#">Manual Review</Link>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item header>*/}
                {/*    <Link to="#">Data Sources</Link>*/}
                {/*</Menu.Item>*/}


                <Menu.Menu position="right">
                    <Menu.Item>
                        <Link to="/profile">
                            <Icon name='user circle' size='big' />
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Button onClick={this.handleLogoutClick}>Logout</Button>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }
}
