import React from "react";
import {List, Button, Modal, ModalHeader} from "semantic-ui-react";
import API from "../../../utils/API";
import MFABackup from "./MFABackup";

export default class MFAActive extends React.Component {

    state = {
        backupCodes: [],
        modalOpen: false
    }

    setModalOpen = (isOpen) => {
        this.setState({modalOpen: isOpen});
    }

    generateCodes = (method) => {
        API.postGenerateBackupCodes(localStorage.getItem('auth_token'), method)
            .then(res => {
                this.setState({
                    modalOpen: true,
                    backupCodes: res.data.backup_codes
                });
            });
    }

    render() {
        return (
            <div>
                <List>
                    <List.Item>
                        <List.Header>Active MFA Method</List.Header>
                        {this.props.method === 'email' ? 'Email' : 'Authenticator App'}
                    </List.Item>
                </List>
                <Button onClick={() => this.generateCodes(this.props.method)} content='Generate Backup Codes' />
                <Modal
                    closeIcon
                    open={this.state.modalOpen}
                    onClose={() => this.setModalOpen(false)}
                    onOpen={() => this.setModalOpen(true)}
                >
                    <ModalHeader content='Multi-Factor Authentication Backup Codes' />
                    <Modal.Content>
                        <MFABackup setModalOpen={this.setModalOpen} backupCodes={this.state.backupCodes} />
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}
