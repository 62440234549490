import React from "react";
import {Divider, Form, Icon, Message} from "semantic-ui-react";

export default class ApiKeySecret extends React.Component {

    copyClick = (e) => {
        e.target.closest('div .input').children[0].select();
        document.execCommand('copy');
        e.target.focus();
    }

    render() {
        return (
            <div id='api-key-secret'>
                <Divider />
                <Form warning>
                    <Form.Group widths='equal'>
                        <Form.Input
                            label='API Key'
                            id='api-key'
                            value={this.props.apiKey}
                            icon={{ name:'copy', link:true, onClick:this.copyClick }}
                        />
                        <Form.Input
                            label='API Secret'
                            id='api-secret'
                            value={this.props.apiSecret}
                            icon={{ name:'copy', link:true, onClick:this.copyClick }}
                        />
                    </Form.Group>
                    <Message warning icon>
                        <Icon name='exclamation triangle' />
                        <Message.Content>
                            <Message.Header>Please copy your client secret now and store in a secure location.</Message.Header>
                            You will not be able to access it here again, and GDC cannot recover lost API secrets.
                        </Message.Content>
                    </Message>
                </Form>
            </div>
        );
    }
}
