import React from "react";
import {Table} from "semantic-ui-react";
import APIKeysTableRow from "./APIKeysTableRow";

export default class ApiKeysTable extends React.Component {

    get tableItems() {
        return Object.entries(this.props.keys).map( tenant => {
            const [key, value] = tenant;
            if (value.length === 1) {
                return (
                    <Table.Row key={value[0].apiKey}>
                        <Table.Cell rowSpan={value.length}>{key}</Table.Cell>
                        {this.getTableCells(value)}
                    </Table.Row>
                )
            } else if (value.length > 1) {
                return value.map((obj, idx) => {
                    if (idx === 0) {
                        return (
                            <Table.Row key={obj.apiKey}>
                                <Table.Cell rowSpan={value.length}>{key}</Table.Cell>
                                {this.getTableCells([obj])}
                            </Table.Row>
                        )
                    } else {
                        return (
                            <Table.Row key={obj.apiKey}>
                                {this.getTableCells([obj])}
                            </Table.Row>
                        )
                    }
                });
            }
        });
    }

    getTableCells(tableData) {
        return tableData.map( data => (
            <APIKeysTableRow tenant={data} handleDeleteClick={this.props.handleDeleteClick} />
        ));
    }

    render() {
        return (
            <Table structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Tenant</Table.HeaderCell>
                        <Table.HeaderCell>API Key</Table.HeaderCell>
                        <Table.HeaderCell>Date Issued (UTC)</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.tableItems}
                </Table.Body>
            </Table>
        );
    }
}
