import React from "react";
import Header from "../home-page/Header";
import Sidebar from "../home-page/Sidebar";
import {Breadcrumb, Container, Divider} from "semantic-ui-react";

export default class SupportPage extends React.Component {

    render() {
        return (
            <div>
                <Header />
                <Sidebar />
                <div className="page-content-container">
                    <Breadcrumb>
                        <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>Support</Breadcrumb.Section>
                    </Breadcrumb>
                    <Divider hidden />
                    <Container>
                        <p>For help or questions please visit the
                            <a target="_blank" rel="noopener noreferrer" href="https://help.globaldataconsortium.com/hc/en-us"> GDC Help Center</a>.
                        </p>
                    </Container>
                </div>
            </div>
        );
    }
}
