import React from 'react';
import "./styles/styles.global.scss";
import './App.css';
import Routes from "./Routes";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
