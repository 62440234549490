import React, {Component} from "react";
import {Button, Container, Divider, Form, Image, Message, Segment} from "semantic-ui-react";
import GDCLogo from "../../styles/images/GDCLogo_Full_With_TM_04.jpg";
import API from "../../utils/API";
import {history} from "../../Routes";


export default class MFALoginCode extends Component {

    state = {
        code: '',
        error: false,
        errorMsg: [],
        success: false
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { code } = this.state;

        API.postLoginCode(localStorage.getItem('ephemeral_token'), code)
            .then(response => {
                localStorage.setItem('auth_token', response.data.auth_token);
                this.setState({error: false, success: true});
                history.push('/');
            })
            .catch(error => {
                this.setState({error: true});
                if (error.response.data) {
                    this.setState({errorMsg: Object.values(error.response.data)[0]});
                }
            });
    }

    render() {
        return (
            <div>
                <Container className='login-container' text>
                    <Segment padded>
                        <Image src={GDCLogo} alt="GDC Logo" size='medium' centered />
                        <Divider hidden />
                        <h3>Enter your authentication code</h3>
                        <p>Please check your email or authenticator app for the code.</p>
                        <Form error={this.state.error} hidden={this.state.success} onSubmit={this.handleSubmit}>
                            <Form.Input
                                autoFocus
                                placeholder='6 digit code'
                                name='code'
                                onChange={this.onChange}
                            />
                            <Message
                                error
                                header='Action Forbidden'
                                list={this.state.errorMsg}
                            />
                            <Button type='submit' content='Submit' />
                        </Form>
                    </Segment>
                </Container>
            </div>
        );
    }
}
