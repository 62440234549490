import React from 'react';
import Header from "../home-page/Header";
import Sidebar from "../home-page/Sidebar";
import TenantTable from "./TenantTable";
import {Breadcrumb, Container, Divider} from "semantic-ui-react";
import API from "../../utils/API";

export default class TenantPage extends React.Component {

    state = {
        tenants: [
            {
                name: '',
                created: '',
                modified: ''
            }
        ]
    }

    componentDidMount() {
        this.getState();
    }

    getState = () => {
        API.getTenants(localStorage.getItem('auth_token'))
            .then(res => {
                this.setState({tenants: res.data.tenants});
            });
    }

    render() {
        return (
            <div>
                <Header />
                <Sidebar />
                <div className="page-content-container">
                    <Breadcrumb>
                        <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section link>Admin</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>Tenants</Breadcrumb.Section>
                    </Breadcrumb>
                    <Divider hidden />
                    <Container>
                        <TenantTable tenants={this.state.tenants} />
                    </Container>
                </div>
            </div>
        );
    }
}
