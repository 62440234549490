import React from 'react';
import Sidebar from "../../components/home-page/Sidebar";
import Header from "../../components/home-page/Header";


export default class HomePage extends React.Component {

    render() {

        return (
            <div className="home-page-container">
                <Header />
                <Sidebar />
            </div>
        );
    }
}
