import React from "react";
import {Step} from "semantic-ui-react";
import MFARegister from "./MFARegister";
import MFAVerify from "./MFAVerify";
import MFABackup from "./MFABackup";

export default class MFASetup extends React.Component {
    state = {
        activeStep: 'register',
        selectedMethod: '',
        qrCode: '',
        backupCodes: ''
    }

    updateState = (data) => {
        this.setState(data);
    }

    render() {
        let {activeStep, selectedMethod, backupCodes} = this.state;
        return (
            <div>
                <Step.Group widths={3}>
                    <Step active={activeStep === 'register'}>
                        <Step.Content>
                            <Step.Title>Register</Step.Title>
                            <Step.Description>Choose your MFA method</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={activeStep === 'verify'}>
                        <Step.Content>
                            <Step.Title>Verify</Step.Title>
                            <Step.Description>Enter your security code</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={activeStep === 'backup'}>
                        <Step.Content>
                            <Step.Title>Backup Codes</Step.Title>
                            <Step.Description>Keep your codes safe</Step.Description>
                        </Step.Content>
                    </Step>
                </Step.Group>

                { activeStep === 'register' && <MFARegister updateParent={this.updateState} /> }
                { activeStep === 'verify' && <MFAVerify updateParent={this.updateState} method={selectedMethod} qrCode={this.state.qrCode} /> }
                { activeStep === 'backup' && <MFABackup backupCodes={backupCodes} setModalOpen={this.props.setModalOpen}/> }
            </div>
        );
    }
}
