import React, {Component} from "react";
import {Button, Form, Grid, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {history} from "../../Routes"
import API from "../../utils/API";

export default class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: false
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleError() {
        this.setState({error: true})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { username, password } = this.state;

        API.getUserAuthForLogin(username, password)
            .then(function (response) {
                if ('ephemeral_token' in response.data) {
                    localStorage.setItem('ephemeral_token', response.data.ephemeral_token);
                    history.push('/login/code');
                } else if ('auth_token' in response.data) {
                    localStorage.setItem('auth_token', response.data.auth_token);
                    history.push('/');
                }
            })
            .catch((error) => {
                console.error(error);
                this.handleError();
            });
    }

    render() {
        return (
            <Form error={this.state.error} className='login-form' onSubmit={this.handleSubmit}>
                <Form.Input
                    placeholder='Username'
                    icon='user'
                    iconPosition='left'
                    name='username'
                    onChange={this.onChange}
                />
                <Form.Input
                    type='password'
                    placeholder='Password'
                    icon='lock'
                    iconPosition='left'
                    name='password'
                    onChange={this.onChange}
                />
                <Message
                    error
                    header='Action Forbidden'
                    content='You must enter a valid username and password.'
                />
                <Grid columns={2} >
                    <Grid.Column>
                        <Button type='submit' content='Login' />
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <Link to={'/reset_password_request'}>Forgot Password?</Link>
                    </Grid.Column>
                </Grid>
            </Form>
        );
    }
}
