import React from "react";
import {Button, Divider, Form, Grid} from "semantic-ui-react";
import API from "../../../utils/API";

const QRCode = require('qrcode.react');

export default class MFAVerify extends React.Component {

    state = {
        code: '',
        allowNext: false
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, allowNext: true });
    }

    submitMfaActivation = () => {
        API.postMfaActivationConfirm(localStorage.getItem('auth_token'), this.props.method, this.state.code)
            .then(res => {
                this.props.updateParent({
                    activeStep:'backup',
                    backupCodes: res.data.backup_codes
                });
            })
    }

    render() {
        return (
            <div>
                {this.props.method === 'app' &&
                <Grid columns={2}>
                    <Grid.Column>
                        <p>Download an authenticator app on your mobile device (such as Google Authenticator).</p>
                        <p>Open the app and scan this QR code to receive your security code.</p>
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <QRCode value={this.props.qrCode}/>
                        </div>
                    </Grid.Column>
                </Grid>
                }
                <Form>
                    <Form.Input
                        width={8}
                        autoFocus
                        label='Security Code'
                        placeholder='6 digit code'
                        name='code'
                        onChange={this.onChange}
                    />
                </Form>
                <Divider />
                <Button floated='right' disabled={!this.state.allowNext} onClick={this.submitMfaActivation} content='Next' style={{marginBottom: 15}} />
            </div>
        );
    }
}
