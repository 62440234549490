import React from "react";
import {Popup, Table} from "semantic-ui-react";
import CountriesPopupContent from "./CountriesPopupContent";
import lodash from "lodash";
import FormatTime from "../../utils/FormatTime";

export default class TenantTable extends React.Component {

    countryColumnCell = (countries) => {
        if (countries && countries.length > 0) {
            if (countries.includes('UK')) {
                lodash.pull(countries, 'UK');
                if (!countries.includes('GB')) {
                    countries.push('GB');
                }
            }
            return (
                <Popup
                    trigger={<a>{ countries.length }</a>}
                    content={<CountriesPopupContent countries={countries}/>}
                    position='right center'
                />
            );
        } else {
            return <a>0</a>
        }
    }

    render() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Configuration</Table.HeaderCell>
                        <Table.HeaderCell>Countries</Table.HeaderCell>
                        <Table.HeaderCell>Test Cache</Table.HeaderCell>
                        <Table.HeaderCell>Created Date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.values(this.props.tenants).map(
                        ({ name, config, countries, created }) => {
                            return (
                                <Table.Row>
                                    <Table.Cell>{name}</Table.Cell>
                                    <Table.Cell>{config}</Table.Cell>
                                    <Table.Cell>{this.countryColumnCell(countries)}</Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell>{FormatTime.displayDate(created)}</Table.Cell>
                                </Table.Row>
                            );
                        }
                    )}
                </Table.Body>
            </Table>
        );
    }
}
