import axios from 'axios';
import config from '../Config.json';

const baseURI = config.baseURI;

export default {

    getUserAuthForLogin: (username, password) => {
        return axios.post(`${baseURI}/auth/login/`,
            {username, password}
            );
    },

    postLoginCode: (ephemeral_token, code) => {
        return axios.post(`${baseURI}/auth/login/code/`,
            {ephemeral_token, code}
        );
    },

    postMfaActivation: (authToken, method) => {
      return axios.post(`${baseURI}/auth/${method}/activate/`,
          {method},
          {
              headers: {
                  Authorization: "Token " + authToken
              }
          });
    },

    postMfaActivationConfirm: (authToken, method, code) => {
        return axios.post(`${baseURI}/auth/${method}/activate/confirm/`,
            {code},
            {
                headers: {
                    Authorization: "Token " + authToken
                }
            });
    },

    postMfaDeactivation: (authToken, method) => {
        return axios.post(`${baseURI}/auth/${method}/deactivate/`,
            {},
            {
                headers: {
                    Authorization: "Token " + authToken
                }
            });
    },

    getUserMfaMethod: (authToken) => {
        return axios.get(`${baseURI}/auth/mfa/user-active-methods/`,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    postGenerateBackupCodes: (authToken, method) => {
        return axios.post(`${baseURI}/auth/${method}/codes/regenerate/`,
            {method},
            {
                headers: {
                    Authorization: "Token " + authToken
                }
            });
    },

    // not currently implemented
    // postResendLoginCode: (method) => {
    //     return axios.post(`${baseURI}/auth/code/request/`,
    //         {method}
    //     );
    // },

    postResetPasswordRequest: (email) => {
        return axios.post(`${baseURI}/auth/users/reset_password/`,
            {email}
        );
    },

    postResetPasswordConfirm: (uid, token, new_password, re_new_password) => {
        return axios.post(`${baseURI}/auth/users/reset_password_confirm/`,
            {uid, token, new_password, re_new_password}
        );
    },

    postSetPassword: (authToken, data) => {
        return axios.post(`${baseURI}/auth/users/set_password/`, data,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    getUserInfo: (authToken) => {
        return axios.get(`${baseURI}/auth/users/me/`,
        {
            headers: {
                Authorization: "Token " + authToken
            },
        });
    },

    updateUserInfo: (authToken, data) => {
        return axios.put(`${baseURI}/auth/users/me/`, data,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    getOrgInfo: (authToken) => {
        return axios.get(`${baseURI}/org_info`,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    getTenants: (authToken) => {
        return axios.get(`${baseURI}/tenant`,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    getUsers: (authToken) => {
        return axios.get(`${baseURI}/user/list`,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    postUserAdd: (authToken, data) => {
        return axios.post(`${baseURI}/user/add`, data,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    postUserEdit: (authToken, data) => {
        return axios.post(`${baseURI}/user/edit`, data,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    postUserDelete: (authToken, data) => {
        return axios.post(`${baseURI}/user/delete`, data,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    getApiKeys: (authToken) => {
        return axios.get(`${baseURI}/apikeys`,
            {
                headers: {
                    Authorization: "Token " + authToken
                },
            });
    },

    postApiKeys: (authToken, data) => {
        return axios.post(`${baseURI}/apikeys`, data,
        {
            headers: {
                Authorization: "Token " + authToken
            },
        });
    },

    updateOrgInfo: (authToken, formData) => {
        return axios.post(`${baseURI}/org_info`, formData,
        {
            headers: {
                Authorization: "Token " + authToken
            },
        });
    },

    userLogout: (authToken) => {
        return axios.post(`${baseURI}/auth/logout/`,
            {},
            {
                headers: {
                    Authorization: "Token " + authToken
                },
        });
    },

};
