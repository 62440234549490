import React from "react";
import lodash from "lodash/core";

const countriesData = require("../../utils/countries.json");

export default class CountriesPopupContent extends React.Component {

    get countryList() {
        const {countries} = this.props;
        if (countries) {
            return (
                countries.map(
                    c => <li>{lodash.find(countriesData, {
                        value: c
                    }).label}</li>)
            );
        }
    }

    render() {
        return (
            <ul>
                {this.countryList}
            </ul>
        )
    }
}
